import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  SpaceBetween,
  FormField,
  Select,
  Input,
} from '@cloudscape-design/components';
import { SCENARIO_OPTIONS } from '../constants/scenario-options';
import { SLICE_OPTIONS } from '../constants/slice-options';
import { OptionDefinition } from 'src/common/types/OptionDefinition';
import type { SelectChangeEvent } from 'src/common/types/Events';

interface FormSectionProps {
  selectedScenario: OptionDefinition | null;
  selectedSlice: OptionDefinition | null;
  generatedName: string;
  onScenarioChange: (event: SelectChangeEvent) => void;
  onSliceChange: (event: SelectChangeEvent) => void;
}

const FormSection: React.FC<FormSectionProps> = ({
  selectedScenario,
  selectedSlice,
  generatedName,
  onScenarioChange,
  onSliceChange,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <SpaceBetween size="m">
        <FormField label={t('scenario')} stretch>
          <Select
            data-testid="scenario-selector"
            selectedOption={selectedScenario}
            onChange={onScenarioChange}
            options={SCENARIO_OPTIONS}
            placeholder={t('cf_scenario_placeholder')}
            ariaRequired
          />
        </FormField>
        <FormField label={t('slice_name')} stretch>
          <Select
            data-testid="slice-selector"
            selectedOption={selectedSlice}
            onChange={onSliceChange}
            options={SLICE_OPTIONS}
            placeholder={t('cf_slice_placeholder')}
            disabled={!selectedScenario}
            ariaRequired
          />
        </FormField>
        <FormField label={t('Name')} stretch>
          <Input
            data-testid="name"
            value={generatedName}
            disabled
            readOnly
            placeholder={t('name_placeholder')}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};

export default FormSection;
