import { OptionDefinition } from 'src/common/types/OptionDefinition';

// Todo : These values has to fetched from backend in later phase
// Ref SIM : https://issues.amazon.com/issues/P182021945.
export const SLICE_OPTIONS: OptionDefinition[] = [
  { label: 'CFFIXED_INVENTORY_DAILY', value: 'CFFIXED_INVENTORY_DAILY' },
  { label: 'CFFIXED_INVENTORY_MONTHLY', value: 'CFFIXED_INVENTORY_MONTHLY' },
  { label: 'CFFIXED_THROUGHPUT_DAILY', value: 'CFFIXED_THROUGHPUT_DAILY' },
  { label: 'CFFIXED_THROUGHPUT_MONTHLY', value: 'CFFIXED_THROUGHPUT_MONTHLY' },
];
