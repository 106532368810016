import { COASegment } from '@amzn/allocations-service';
import { TreeCheckboxSelectionKeys } from 'primereact/tree';
import { Reducer } from 'react';
import { TreeNode } from 'src/common/components/tree-select/TreeSelect';
import { DropdownStatus } from 'src/common/types';
import { CHANNEL_TYPE_OPTIONS } from 'src/common/components/driver-mapping-components/Segment';

export interface DimensionValue {
  defaultValue?: string;
  hierarchyName?: string;
  value: TreeCheckboxSelectionKeys;
  options?: TreeNode[];
  startKey?: number;
  status: DropdownStatus;
  optionsBeforeAppliedFilters?: TreeNode[];
}

export type DimensionState = Partial<Record<COASegment, DimensionValue>>;

export enum DimensionActionType {
  UPDATE = 'UPDATE',
  FILTER = 'FILTER',
  FILTER_RESET = 'FILTER_RESET',
}

interface DimensionAction {
  type: DimensionActionType;
  payload: {
    id: COASegment;
    value: Partial<DimensionValue>;
  };
}

export const initialState: DimensionState = {
  [COASegment.ACCOUNT]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
  [COASegment.CHANNEL]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
  [COASegment.COMPANY]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
  [COASegment.COSTCENTER]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
  [COASegment.LOCATION]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
  [COASegment.PRODUCT]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
  [COASegment.PROJECT]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
};

export const initialStateSliceWorkflow: DimensionState = {
  [COASegment.ACCOUNT]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
  [COASegment.CHANNELTYPE]: {
    value: {},
    status: DropdownStatus.FINISHED,
    options: CHANNEL_TYPE_OPTIONS,
  },
  [COASegment.COMPANY]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
  [COASegment.COSTCENTER]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
  [COASegment.LOCATION]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
  [COASegment.PRODUCT]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
  [COASegment.PROJECT]: {
    value: {},
    status: DropdownStatus.LOADING,
  },
};

export const reducer: Reducer<DimensionState, DimensionAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case DimensionActionType.UPDATE: {
      const { id } = action.payload;
      const currentState = state[id] ?? {
        value: {},
        status: DropdownStatus.LOADING,
      };
      return {
        ...state,
        [id]: {
          ...currentState,
          ...action.payload.value,
          optionsBeforeAppliedFilters: action.payload.value
            .optionsBeforeAppliedFilters?.length
            ? action.payload.value.optionsBeforeAppliedFilters
            : currentState.optionsBeforeAppliedFilters ?? [],
        },
      };
    }
    case DimensionActionType.FILTER: {
      const { id } = action.payload;
      const currentState = state[id] ?? {
        value: {},
        status: DropdownStatus.LOADING,
      };
      return {
        ...state,
        [id]: {
          ...currentState,
          optionsBeforeAppliedFilters: currentState.optionsBeforeAppliedFilters
            ?.length
            ? currentState.optionsBeforeAppliedFilters
            : currentState.options ?? [],
        },
      };
    }
    case DimensionActionType.FILTER_RESET: {
      const { id } = action.payload;
      const currentState = state[id] ?? {
        value: {},
        status: DropdownStatus.LOADING,
      };
      return {
        ...state,
        [id]: {
          ...currentState,
          options: currentState.optionsBeforeAppliedFilters ?? [],
          optionsBeforeAppliedFilters: [],
        },
      };
    }
    default:
      return state;
  }
};
