import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { COASegment } from '@amzn/allocations-service';
import { TFunc } from 'src/common/types';

export const filteringProperties = (t: TFunc): PropertyFilterProperty[] => [
  {
    propertyLabel: t('account'),
    key: 'account',
    groupValuesLabel: t('group_value_label', { label: t('account') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('cost_center'),
    key: 'costCenter',
    groupValuesLabel: t('group_value_label', { label: t('cost_center') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('company_code'),
    key: 'company',
    groupValuesLabel: t('group_value_label', { label: t('company_code') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('location_code'),
    key: 'location',
    groupValuesLabel: t('group_value_label', { label: t('location_code') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('product_code'),
    key: 'product',
    groupValuesLabel: t('group_value_label', { label: t('product_code') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('project_code'),
    key: 'project',
    groupValuesLabel: t('group_value_label', { label: t('project_code') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('channel_type'),
    key: 'channelType',
    groupValuesLabel: t('group_value_label', { label: t('channel_type') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('allocation_function'),
    key: 'allocationFns',
    groupValuesLabel: t('group_value_label', {
      label: t('allocation_function'),
    }),
    operators: [':', '!:'],
    defaultOperator: ':',
  },
  {
    propertyLabel: t('drivers'),
    key: 'drivers',
    groupValuesLabel: t('group_value_label', { label: t('drivers') }),
    operators: [':', '!:'],
    defaultOperator: ':',
  },
];

export const sliceDriverMappingFilterOptions = (
  t: TFunc,
): Partial<
  Record<COASegment, { id: COASegment; label: string; visible: boolean }>
> => ({
  [COASegment.ACCOUNT]: {
    id: COASegment.ACCOUNT,
    label: t('account'),
    visible: true,
  },
  [COASegment.COSTCENTER]: {
    id: COASegment.COSTCENTER,
    label: t('cost_center'),
    visible: true,
  },
  [COASegment.COMPANY]: {
    id: COASegment.COMPANY,
    label: t('company_code'),
    visible: true,
  },
  [COASegment.LOCATION]: {
    id: COASegment.LOCATION,
    label: t('location_code'),
    visible: true,
  },
  [COASegment.PRODUCT]: {
    id: COASegment.PRODUCT,
    label: t('product_code'),
    visible: true,
  },
  [COASegment.PROJECT]: {
    id: COASegment.PROJECT,
    label: t('project_code'),
    visible: true,
  },
  [COASegment.CHANNELTYPE]: {
    id: COASegment.CHANNELTYPE,
    label: t('channel_type'),
    visible: true,
  },
});
