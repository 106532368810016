import { COASegment } from '@amzn/allocations-service';
import type { DriverMappingRecord } from '@amzn/allocations-service';
import { DriverMapping } from 'src/common/types/DriverMapping';
import { Config } from 'src/common/types/Config';
import { TreeNode } from 'src/common/components/tree-select/TreeSelect';

const getTreeNodeValue = (value: string) => value.split('|').pop() ?? '';

export const getDriverMapping = (
  driverMapping: DriverMapping,
  dimension: COASegment,
  value: string,
) => {
  switch (dimension) {
    case COASegment.ACCOUNT: {
      driverMapping['account'] = getTreeNodeValue(value);
      driverMapping['accountExclusions'] = [];
      break;
    }
    case COASegment.CHANNEL: {
      driverMapping['channelCode'] = getTreeNodeValue(value);
      driverMapping['channelCodeExclusions'] = [];
      break;
    }
    case COASegment.COMPANY: {
      driverMapping['companyCode'] = getTreeNodeValue(value);
      driverMapping['companyCodeExclusions'] = [];
      break;
    }
    case COASegment.COSTCENTER: {
      driverMapping['costCenter'] = getTreeNodeValue(value);
      driverMapping['costCenterExclusions'] = [];
      break;
    }
    case COASegment.LOCATION: {
      driverMapping['locationCode'] = getTreeNodeValue(value);
      driverMapping['locationCodeExclusions'] = [];
      break;
    }
    case COASegment.PRODUCT: {
      driverMapping['productCode'] = getTreeNodeValue(value);
      driverMapping['productCodeExclusions'] = [];
      break;
    }
    case COASegment.PROJECT: {
      driverMapping['projectCode'] = getTreeNodeValue(value);
      driverMapping['projectCodeExclusions'] = [];
      break;
    }
  }
};

export const getDriverMappingRecord = (
  driverMapping: DriverMappingRecord,
  dimension: COASegment,
  value: string,
) => {
  switch (dimension) {
    case COASegment.ACCOUNT: {
      driverMapping['account'] = getTreeNodeValue(value);
      driverMapping['accountExclusions'] = [];
      break;
    }
    case COASegment.CHANNELTYPE: {
      driverMapping['channelType'] = getTreeNodeValue(value);
      driverMapping['channelTypeExclusions'] = [];
      break;
    }
    case COASegment.COMPANY: {
      driverMapping['companyCode'] = getTreeNodeValue(value);
      driverMapping['companyCodeExclusions'] = [];
      break;
    }
    case COASegment.COSTCENTER: {
      driverMapping['costCenter'] = getTreeNodeValue(value);
      driverMapping['costCenterExclusions'] = [];
      break;
    }
    case COASegment.LOCATION: {
      driverMapping['locationCode'] = getTreeNodeValue(value);
      driverMapping['locationCodeExclusions'] = [];
      break;
    }
    case COASegment.PRODUCT: {
      driverMapping['productCode'] = getTreeNodeValue(value);
      driverMapping['productCodeExclusions'] = [];
      break;
    }
    case COASegment.PROJECT: {
      driverMapping['projectCode'] = getTreeNodeValue(value);
      driverMapping['projectCodeExclusions'] = [];
      break;
    }
  }
};

/**
 * This function will return the path of the driver mapping.
 */
export const getDriverMappingPath = (config: Config) =>
  `business=${config.business?.value}/methodology=${config.methodology?.value}/region=${config.region?.value}/`;

/**
 * This function will return all possible combinations of input array.
 * Example: getCombinations([1, 2], [3, 4]) => [[1,3],[1,4],[2,3],[2,4]]
 */
export const getCombinations = (...arr: string[][]) =>
  arr.reduce<string[][]>(
    (acc, val) =>
      acc
        .map((el) => val.map((element) => el.concat([element])))
        .reduce((acc, val) => acc.concat(val), []),
    [[]],
  );

/**
 * This function returns a map of child nodes for each node in the tree.
 */
export const getTreeChildNodeMap = (
  treeNode: TreeNode[],
): Record<string, string[]> => {
  const treeChildNodeMap: Record<string, string[]> = {};

  const traverseNode = (node: TreeNode) => {
    if (node.children?.length) {
      treeChildNodeMap[node.key as string] = node.children.map(
        (child) => child.key as string,
      );
      node.children.forEach(traverseNode);
    }
  };

  treeNode.forEach(traverseNode);
  return treeChildNodeMap;
};

export enum DriverMappingAction {
  ADD = 'ADD',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}

export const getDriverMappingData = (
  driverMappings: DriverMapping[] = [],
  options: {
    operation: DriverMappingAction;
    filter?: DriverMapping;
    driverMappings?: DriverMapping[];
  },
): DriverMapping[] => {
  switch (options.operation) {
    case DriverMappingAction.ADD:
      return [...(options?.driverMappings ?? []), ...driverMappings];
    case DriverMappingAction.DELETE:
      return driverMappings.filter(
        (mapping) => JSON.stringify(mapping) !== JSON.stringify(options.filter),
      );
    case DriverMappingAction.EDIT:
      return driverMappings.flatMap((mapping) => {
        if (JSON.stringify(mapping) === JSON.stringify(options.filter)) {
          return options.driverMappings ?? [];
        }
        return mapping;
      });
  }
};

/**
 * This function will return the value of the driver mapping based on the id.
 * @param DriverMapping - DriverMapping object
 * @param id - COASegment enum value
 * @returns - string value of the driver mapping or undefined if not found
 */
export const getDriverMappingValue = (
  driverMapping: DriverMapping,
  id: COASegment,
): string | undefined => {
  const mappingKeys: Partial<Record<COASegment, keyof DriverMapping>> = {
    [COASegment.ACCOUNT]: 'account',
    [COASegment.CHANNEL]: 'channelCode',
    [COASegment.COMPANY]: 'companyCode',
    [COASegment.COSTCENTER]: 'costCenter',
    [COASegment.LOCATION]: 'locationCode',
    [COASegment.PRODUCT]: 'productCode',
    [COASegment.PROJECT]: 'projectCode',
  };

  const key = mappingKeys[id];
  return key ? (driverMapping[key] as string) : undefined;
};

/**
 * This function will return the value of the driver mapping based on the id.
 * @param DriverMappingRecord - DriverMappingRecord object
 * @param id - COASegment enum value
 * @returns - string value of the driver mapping or undefined if not found
 */
export const getDriverMappingRecordValue = (
  driverMapping: DriverMappingRecord,
  id: COASegment,
): string | undefined => {
  const mappingKeys: Partial<Record<COASegment, keyof DriverMappingRecord>> = {
    [COASegment.ACCOUNT]: 'account',
    [COASegment.CHANNELTYPE]: 'channelType',
    [COASegment.COMPANY]: 'companyCode',
    [COASegment.COSTCENTER]: 'costCenter',
    [COASegment.LOCATION]: 'locationCode',
    [COASegment.PRODUCT]: 'productCode',
    [COASegment.PROJECT]: 'projectCode',
  };

  const key = mappingKeys[id];
  return key ? (driverMapping[key] as string) : undefined;
};
