import React, { useCallback, useEffect, useState } from 'react';
import Container from '@cloudscape-design/components/container';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { GetSliceRequest, GetSliceResponse } from '@amzn/allocations-service';
import { Environment } from 'src/common/constants/environment';
import QueryKey from 'src/api/QueryKey';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';
import ScenarioSelector from 'src/common/components/ScenarioSelector';
import SliceSelector from 'src/common/components/SliceSelector';
import SliceInformationForDriverMapping from 'src/common/components/SliceInformationForDriverMapping';
import DriverMappingForSlice from 'src/common/components/DriverMappingForSlice';
import { OptionDefinition } from 'src/common/types/OptionDefinition';
import { filteringProperties } from 'src/shadow-pnl-allocations/pages/configure-slice-allocation-methodology-page/utils/slice-driver-mapping-filter-properties';

const ConfigureSliceAllocationMethodologyPage = () => {
  const { t } = useTranslation();
  const [selectedScenario, setSelectedScenario] =
    useState<OptionDefinition | null>(null);
  const [sliceOptions, setSliceOptions] = useState<OptionDefinition[]>([]);
  const [selectedSlice, setSelectedSlice] = useState<OptionDefinition | null>(
    null,
  );

  const handleScenarioChange = useCallback(
    (selectedScenario: OptionDefinition) => {
      setSelectedScenario(selectedScenario);
      setSelectedSlice(null);
    },
    [selectedScenario],
  );

  const handleSliceChange = useCallback(
    (selectedSlice: OptionDefinition) => {
      setSelectedSlice(selectedSlice);
    },
    [selectedSlice],
  );

  async function getSlices(sliceIds: string[]) {
    const getSliceRequests: GetSliceRequest[] = sliceIds!.map((sliceId) => ({
      sliceId,
    }));
    const slices = await Promise.all(
      AllocationServiceApi.getSliceInformationBatch(getSliceRequests),
    );
    return new Map(slices.map((slice) => [slice.sliceId!, slice]));
  }

  const slicesByScenarioQuery = useQuery({
    queryKey: [QueryKey.GetSlicesForSelectedScenario, selectedScenario],
    queryFn: async () => {
      const slicesForSelectedScenario =
        await AllocationServiceApi.getSlicesByEnvironmentAndScenario({
          environment: Environment.PRODUCTION,
          scenario: selectedScenario!.label!,
        });
      return getSlices(slicesForSelectedScenario!.sliceIds!);
    },
    enabled: Boolean(selectedScenario),
  });

  const getSliceOptions = () =>
    Array.from(slicesByScenarioQuery.data!.entries()).map(
      ([sliceId, slice]: [string, GetSliceResponse]) => ({
        label: slice.sliceName!,
        value: sliceId,
      }),
    );

  useEffect(() => {
    if (slicesByScenarioQuery.data) {
      setSliceOptions(getSliceOptions());
    }
  }, [selectedScenario, slicesByScenarioQuery.data]);

  return (
    <div data-testid="slice-allocation-methodology-configure-page">
      <Container>
        <SpaceBetween size="xxl">
          <ScenarioSelector scenarioCallback={handleScenarioChange} />

          <SliceSelector
            sliceOptions={sliceOptions}
            sliceSelectionCallback={handleSliceChange}
            data-testid="slice-selection-wrapper"
            isFetchingSlices={slicesByScenarioQuery.isLoading}
          />

          {selectedSlice && (
            <SliceInformationForDriverMapping
              key={slicesByScenarioQuery.data!.get(selectedSlice.value)!.dmId!}
              driverMappingId={
                slicesByScenarioQuery.data!.get(selectedSlice.value)!.dmId!
              }
            />
          )}

          <DriverMappingForSlice
            key={selectedSlice ? selectedSlice.value : null}
            filteringProperties={filteringProperties(t)}
            header={t('driver_mappings')}
            testId="server-side-driver-mapping-table"
            sliceId={selectedSlice ? selectedSlice.value : null}
            scenario={selectedScenario ? selectedScenario.value : undefined}
          />
        </SpaceBetween>
      </Container>
    </div>
  );
};

export default ConfigureSliceAllocationMethodologyPage;
