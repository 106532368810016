import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SpaceBetween,
  FormField,
  Select,
  Input,
  Checkbox,
  Container,
  Header,
} from '@cloudscape-design/components';
import { Schedule } from 'src/common/types';
import { ScheduleTimeList, WorkdaysList } from 'src/common/constants/workday';
import type { SelectChangeEvent } from 'src/common/types/Events';
import type { FrequencyOption } from 'src/common/constants/frequency';

interface ScheduleSectionProps {
  schedule: Schedule;
  selectedFrequency: FrequencyOption | null;
  selectedSlice: boolean;
  selectedScenario: boolean;
  onScheduleChange: (schedule: Schedule) => void;
}

const ScheduleSection: React.FC<ScheduleSectionProps> = ({
  schedule,
  selectedFrequency,
  selectedSlice,
  selectedScenario,
  onScheduleChange,
}) => {
  const { t } = useTranslation();

  const filteredWorkdays = React.useMemo(() => {
    const startValue = schedule.workdayStart?.value;
    if (!startValue) return [];
    return WorkdaysList.filter(
      (workday) => parseInt(workday.value) >= parseInt(startValue),
    );
  }, [schedule.workdayStart]);

  const handleCheckboxChange = React.useCallback(
    ({ detail }: { detail: { checked: boolean } }) => {
      if (!detail.checked) {
        onScheduleChange({
          ...schedule,
          enable: false,
        });
      } else {
        onScheduleChange({
          ...schedule,
          enable: true,
        });
      }
    },
    [schedule, onScheduleChange],
  );

  const handleScheduleChange =
    (scheduleKey: keyof Schedule) => (event: SelectChangeEvent) => {
      onScheduleChange({
        ...schedule,
        [scheduleKey]: event.detail.selectedOption,
      });
    };

  return (
    <Container header={<Header variant="h3">{t('schedule')}</Header>}>
      <SpaceBetween size="m">
        <Checkbox
          data-testid="enable-schedule"
          checked={schedule.enable}
          onChange={handleCheckboxChange}
          disabled={!selectedSlice || !selectedScenario}
        >
          {t('enable_schedule')}
        </Checkbox>
        <FormField label={t('frequency')} stretch>
          <Input
            data-testid="frequency"
            value={
              schedule.enable && selectedFrequency ? selectedFrequency : ''
            }
            disabled
            readOnly
            placeholder={t('frequency_placeholder')}
          />
        </FormField>
        <FormField label={t('workday_start')} stretch>
          <Select
            data-testid="workday-start"
            options={WorkdaysList}
            selectedOption={schedule.workdayStart}
            onChange={handleScheduleChange('workdayStart')}
            placeholder={t('workday_placeholder')}
            disabled={!schedule.enable || selectedFrequency?.toString() == 'DAILY'}
          />
        </FormField>
        <FormField label={t('workday_end')} stretch>
          <Select
            data-testid="workday-end"
            options={filteredWorkdays}
            selectedOption={schedule.workdayEnd}
            onChange={handleScheduleChange('workdayEnd')}
            placeholder={t('workday_placeholder')}
            disabled={!schedule.enable || !schedule.workdayStart}
          />
        </FormField>
        <FormField label={t('time', { timezone: 'PT' })} stretch>
          <Select
            data-testid="time"
            options={ScheduleTimeList}
            selectedOption={schedule.time}
            onChange={handleScheduleChange('time')}
            placeholder={t('time_placeholder')}
            disabled={!schedule.enable}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};

export default ScheduleSection;
