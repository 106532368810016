import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  COASegment,
  type DriverMappingRecord,
} from '@amzn/allocations-service';
import Modal from '@cloudscape-design/components/modal';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import FormField from '@cloudscape-design/components/form-field';
import Input from '@cloudscape-design/components/input';
import { useQuery } from '@tanstack/react-query';
import Flashbar from '@cloudscape-design/components/flashbar';
import {
  DimensionActionType,
  DimensionValue,
  initialStateSliceWorkflow,
  reducer,
} from 'src/common/components/driver-mapping-components/utils/dimensionsReducer';
import {
  getCombinations,
  getDriverMappingRecord,
} from 'src/common/components/driver-mapping-components/utils/driverMapping';
import { DriverAttributeEditor } from 'src/common/components/driver-mapping-components/DriverAttributeEditor';
import { Config } from 'src/common/types/Config';
import QueryKey from 'src/api/QueryKey';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';
import { getSelectionKeys } from 'src/common/components/tree-select/useTokens';
import { SliceDimensions } from 'src/shadow-pnl-allocations/pages/configure-slice-allocation-methodology-page/SliceDimension';

// Static allocation function for driver mapping
const allocationFunction = 'SplitByRatio';

interface SliceDriverMappingModalProps {
  config: Config;
  driverMapping?: DriverMappingRecord;
  onClose(): void;
  onSubmit(driverMappings: DriverMappingRecord[]): void;
  visible: boolean;
  error?: {
    errorMessage: string;
    onDismiss: () => void;
  };
}

/**
 * This component is specifically for Slice flow since existing component is tightly coupled to use channel code
 *
 */
export const SliceDriverMappingModal: FC<SliceDriverMappingModalProps> = (
  props,
) => {
  const { t } = useTranslation();
  const [drivers, setDrivers] = useState<string[]>(
    () => props.driverMapping?.drivers ?? [],
  );
  const [dimensions, dispatch] = useReducer(reducer, initialStateSliceWorkflow);

  const defaultValues = useQuery({
    queryKey: [QueryKey.GetDefaultValues],
    queryFn: () => AllocationServiceApi.getDefaultValuesByCOASegment(),
    select: (data) => data.defaultValues ?? {},
  });

  const disabled = useMemo(
    () => !drivers.length || !drivers.every((d) => Boolean(d)),
    [drivers],
  );

  const handleDimensionsChange = useCallback(
    (id: string, value: Partial<DimensionValue>) => {
      dispatch({
        type: DimensionActionType.UPDATE,
        payload: {
          id: id as COASegment,
          value,
        },
      });
    },
    [],
  );

  const handleFilterChange = useCallback(
    (id: string, type: DimensionActionType) => {
      dispatch({ type, payload: { id: id as COASegment, value: {} } });
    },
    [],
  );

  useEffect(() => {
    if (
      Object.entries(dimensions)
        .filter(([key]) => key !== COASegment.CHANNELTYPE)
        .every(([_, dimension]) => dimension.defaultValue) ||
      defaultValues.isPending ||
      !defaultValues.data
    ) {
      return;
    }

    Object.entries(defaultValues.data ?? {}).map(([key, value]) => {
      if (key === COASegment.CHANNEL) {
        // Do nothing for CHANNEL for slice workflow
        // We will not receive CHANNEL-TYPE from API since it has not default value driven from backend
        return;
      }
      handleDimensionsChange(key, {
        defaultValue: value.dimensionValue?.fieldId,
        hierarchyName: value.hierarchyName,
      });
    });
  }, [defaultValues, dimensions, handleDimensionsChange]);

  const handleSubmit = () => {
    const dimensionValues = Object.entries(dimensions).map(
      ([key, dimension]) => {
        const { defaultValue, value } = dimension;
        if (!Object.keys(value).length) {
          return defaultValue ? [defaultValue] : [];
        }
        //Passing isChannelType flag as a hack to fix the incorrect hierarchy identification
        return getSelectionKeys(dimension.value, key === 'ChannelType');
      },
    );
    const combinations = getCombinations(...dimensionValues);
    const driverMappings: DriverMappingRecord[] = [];
    combinations.forEach((comb) => {
      const driverMapping: DriverMappingRecord = {
        account: undefined,
        costCenter: undefined,
        companyCode: undefined,
        locationCode: undefined,
        productCode: undefined,
        projectCode: undefined,
        channelType: undefined,
      };
      Object.keys(dimensions).forEach((d, index) =>
        getDriverMappingRecord(driverMapping, d as COASegment, comb[index]),
      );
      driverMapping.drivers = drivers;
      driverMapping.allocationFunctions = [allocationFunction];
      driverMappings.push(driverMapping);
    });
    props.onSubmit(driverMappings);
  };

  return (
    <Modal
      data-testid="slice-driver-mapping-modal"
      size="large"
      header={t('driver_mapping')}
      visible={props.visible}
      onDismiss={props.onClose}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              data-testid="driver-mapping-modal-cancel"
              onClick={props.onClose}
            >
              {t('cancel')}
            </Button>
            <Button
              data-testid="driver-mapping-modal-submit"
              variant="primary"
              disabled={disabled}
              onClick={handleSubmit}
            >
              {props.driverMapping ? t('update') : t('create')}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="l">
        {props.error && (
          <Flashbar
            items={[
              {
                type: 'error',
                dismissible: true,
                onDismiss: props.error.onDismiss,
                content: props.error.errorMessage,
              },
            ]}
          />
        )}

        {defaultValues.isPending && (
          <Flashbar
            items={[
              {
                type: 'info',
                loading: true,
                content: t('default_hierarchy_values_loading'),
              },
            ]}
          />
        )}
        {defaultValues.isError && (
          <Flashbar
            items={[
              { type: 'error', content: t('default_hierarchy_values_error') },
            ]}
          />
        )}
        <SliceDimensions
          driverMapping={props.driverMapping}
          dimensions={dimensions}
          onDimensionChange={handleDimensionsChange}
          onFilterChange={handleFilterChange}
        />
        <FormField stretch label={t('allocation_function')}>
          <Input value={allocationFunction} disabled />
        </FormField>
        <DriverAttributeEditor drivers={drivers} onDriverChange={setDrivers} />
      </SpaceBetween>
    </Modal>
  );
};

export default SliceDriverMappingModal;
