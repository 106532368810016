import { Evidently } from '@aws-sdk/client-evidently';
import { Auth } from 'aws-amplify';

export enum Feature {
  SAMPLE_FEATURE = 'SAMPLE_FEATURE',
  SLICE_MANAGEMENT = 'SLICE_MANAGEMENT',
  CF_ALLOCATION = 'CF_ALLOCATION',
}

export enum FeatureValues {
  Default = 'Default',
  Enabled = 'Enabled',
}

export type FeatureMap = Record<Partial<Feature>, boolean>;

// Initial feature configuration
const placeholderData: FeatureMap = {
  [Feature.SAMPLE_FEATURE]: false,
  [Feature.SLICE_MANAGEMENT]: false,
  [Feature.CF_ALLOCATION]: false,
};

/**
 * This function will create the client from Evidently
 */
const getEvidentlyClient = (): Promise<Evidently> =>
  Auth.currentCredentials().then(
    (credentials) =>
      new Evidently({
        region: 'us-west-2',
        credentials,
      }),
  );

export const EvidentlyServiceApi = {
  async getFeatureMap(userAlias: string): Promise<FeatureMap> {
    const client = await getEvidentlyClient();
    const { results } = await client.batchEvaluateFeature({
      requests: Object.values(Feature).map((feature) => ({
        entityId: userAlias,
        feature,
      })),
      project: 'FinanceAllocationsFrontendWebsite',
    });
    return (
      results?.reduce<FeatureMap>((acc, curr) => {
        const feature = curr.feature?.split('/').pop() as Feature;
        if (!feature) {
          return acc;
        }
        acc[feature] = curr.variation === FeatureValues.Enabled;
        return acc;
      }, placeholderData) ?? placeholderData
    );
  },
};
