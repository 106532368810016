import Url from 'src/common/constants/Url';

export interface LandingPageCard {
  level?: number;
  name: string;
  href?: string;
  description?: string;
  external?: boolean;
}

export interface LandingPageSection {
  title: string;
  cards: LandingPageCard[];
}

export enum LandingPageOption {
  FAM_GAM_AB = 'FAM| GAM| AB',
  SHADOW_PNL = 'Shadow PnL',
  CF_ALLOCATION = 'CF Allocation',
}

export const landingPageOptions = [
  { label: LandingPageOption.FAM_GAM_AB, value: '0' },
  { label: LandingPageOption.SHADOW_PNL, value: '1' },
  { label: LandingPageOption.CF_ALLOCATION, value: '2' },
];

export const getFilteredLandingPageOptions = (
  isPnLEnabled: boolean,
  isCFEnabled: boolean,
) => {
  // Base option (FAM_GAM_AB) is always available
  const baseOption = landingPageOptions[0];
  if (isPnLEnabled && isCFEnabled) {
    // Show all options when both features are enabled
    return landingPageOptions;
  }
  if (isPnLEnabled) {
    // Show FAM_GAM_AB and SHADOW_PNL
    return [
      baseOption,
      landingPageOptions[1], // SHADOW_PNL
    ];
  }
  if (isCFEnabled) {
    // Show FAM_GAM_AB and CF_ALLOCATION
    return [
      baseOption,
      landingPageOptions[2], // CF_ALLOCATION
    ];
  }
  // If no features enabled, show only FAM_GAM_AB
  return [baseOption];
};

export function gettingStartedSection(
  isPnLEnabled: boolean,
  isCFEnabled: boolean,
  optionLabel: string,
): LandingPageSection {
  if (isPnLEnabled && optionLabel === LandingPageOption.SHADOW_PNL) {
    return getPnLAllocationStartedSection();
  }
  if (isCFEnabled && optionLabel === LandingPageOption.CF_ALLOCATION) {
    return getCFAllocationStartedSection();
  }
  return getLegacyAllocationStartedSection();
}

function getLegacyAllocationStartedSection(): LandingPageSection {
  return {
    title: 'getting_started',
    cards: [
      {
        name: 'create_driver',
        href: '/driver/create',
        description: 'create_driver_description',
      },
      {
        name: 'manage_driver',
        href: '/driver/manage',
        description: 'manage_driver_description',
      },
      {
        name: 'create_allocation',
        href: '/allocation/create',
        description: 'create_allocation_description',
      },
      {
        name: 'manage_allocation',
        href: '/allocation/manage',
        description: 'manage_allocation_description',
      },
    ],
  };
}

function getPnLAllocationStartedSection(): LandingPageSection {
  const configureOrViewCards: LandingPageCard[] = [];
  configureOrViewCards.push(
    getConfigureSingleSliceCard(),
    getConfigureSliceGroupCard(),
  );

  const cards: LandingPageCard[] = [
    {
      name: 'allocation_methodology',
      level: 1,
    },
    ...configureOrViewCards,
    {
      name: 'cf_allocations',
      level: 1,
    },
    {
      name: 'slice_manage_allocation',
      href: '/slice/allocation/manage',
      description: 'manage_slice_allocation_description',
    },
    {
      name: 'slice_reporting',
      level: 1,
    },
    {
      name: 'slice_view_report',
      href: 'https://reporting.allocations.finergy.amazon.dev/',
      description: 'view_slice_report_description',
      external: true,
    },
  ];

  return {
    title: 'getting_started',
    cards,
  };
}

function getCFAllocationStartedSection(): LandingPageSection {
  const configureOrViewCards: LandingPageCard[] = [];
  configureOrViewCards.push(getConfigureSingleSliceCard());

  const cards: LandingPageCard[] = [
    {
      name: 'slice_allocation_methodology',
      level: 1,
    },
    ...configureOrViewCards,
    {
      name: 'slice_allocations',
      level: 1,
    },
    {
      name: 'create_cf_allocation',
      href: '/cfallocation/create',
      description: 'create_cf_allocation_description',
    },
    {
      name: 'manage_cf_allocation',
      href: '/cfallocation/manage',
      description: 'manage_slice_allocation_description',
    },
  ];

  return {
    title: 'getting_started',
    cards,
  };
}

export const resources: LandingPageSection = {
  title: 'resources',
  cards: [
    {
      name: 'mappings',
      href: '/general/mappings',
    },
    {
      name: 'reports',
      href: '/general/reports',
    },
    {
      name: 'wiki_page',
      href: Url.wiki,
      external: true,
    },
    {
      name: 'contact_us',
      href: Url.contactUs,
      external: true,
    },
  ],
};

function getConfigureSingleSliceCard(): LandingPageCard {
  return {
    name: 'configure_single_slice',
    href: '/slice/single',
    description: 'configure_single_slice_description',
  };
}

function getConfigureSliceGroupCard(): LandingPageCard {
  return {
    name: 'configure_slice_group',
    href: '/slice/group',
    description: 'configure_slice_group_description',
  };
}
