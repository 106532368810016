import React from 'react';
import { RouteObject } from 'react-router-dom';
import { PrivateRoute } from './pages/layout/PrivateRoute';
import LandingPage from './pages/landing-page/LandingPage';
import AllocationConfigurationPage from './legacy-allocations/pages/create-allocation-page';
import CreateDriverPage from './legacy-allocations/pages/create-driver-page';
import ManageAllocationPage from './legacy-allocations/pages/manage-allocation-page';
import ManageDriverPage from './legacy-allocations/pages/manage-driver-page';
import MappingsPage from './pages/mappings-page';
import ReportsPage from './pages/reports-page';
import DriverDetailsPage from './legacy-allocations/pages/driver-details-page';
import DriverExecutionDetailsTab from './legacy-allocations/pages/driver-details-page/ExecutionDetailsTab';
import AllocationExecutionDetailsTab from './legacy-allocations/pages/allocation-details-page/ExecutionDetailsTab';
import AllocationDetailsPage from './legacy-allocations/pages/allocation-details-page';
import ConfigureSliceGroupPage from 'src/shadow-pnl-allocations/pages/configure-slice-order-page';
import ManageSliceAllocationPage from 'src/shadow-pnl-allocations/pages/manage-slice-allocation-page';
import ConfigureSliceAllocationMethodologyPage from 'src/shadow-pnl-allocations/pages/configure-slice-allocation-methodology-page';
import CreateCFAllocationPage from 'src/cf-allocations/pages/create-cf-allocation-page';

// App routes
export const routes: RouteObject[] = [
  {
    path: '/',
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'slice',
        children: [
          {
            path: 'single',
            element: <ConfigureSliceAllocationMethodologyPage />,
          },
          {
            path: 'group',
            element: <ConfigureSliceGroupPage />,
          },
          {
            path: 'group/:scenario',
            element: <ConfigureSliceGroupPage />,
          },
          {
            path: 'allocation',
            children: [
              {
                path: 'manage',
                element: (
                  <ManageSliceAllocationPage disableDeleteButton={true} />
                ),
              },
              {
                path: 'details',
                element: <AllocationDetailsPage />,
                children: [
                  {
                    path: ':taskId',
                    element: <AllocationExecutionDetailsTab />,
                  },
                ],
              },
            ],
          },
          {
            path: 'report',
            element: <ManageSliceAllocationPage disableDeleteButton={true} />,
          },
        ],
      },
      {
        path: 'driver',
        children: [
          {
            path: 'create',
            element: <CreateDriverPage />,
          },
          {
            path: 'manage',
            element: <ManageDriverPage />,
          },
          {
            path: 'update/:taskId',
            element: <CreateDriverPage />,
          },
          {
            path: 'details',
            element: <DriverDetailsPage />,
            children: [
              {
                path: ':taskId',
                element: <DriverExecutionDetailsTab />,
              },
            ],
          },
        ],
      },
      {
        path: 'allocation',
        children: [
          {
            path: 'create',
            element: <AllocationConfigurationPage />,
          },
          {
            path: 'manage',
            element: <ManageAllocationPage />,
          },
          {
            path: 'update/:taskId',
            element: <AllocationConfigurationPage />,
          },
          {
            path: 'details',
            element: <AllocationDetailsPage />,
            children: [
              {
                path: ':taskId',
                element: <AllocationExecutionDetailsTab />,
              },
            ],
          },
        ],
      },
      {
        path: 'cfallocation',
        children: [
          {
            path: 'create',
            element: <CreateCFAllocationPage />,
          },
          {
            path: 'manage',
            element: <ManageSliceAllocationPage disableDeleteButton={true} />,
          },
        ],
      },
      {
        path: 'general',
        children: [
          {
            path: 'mappings',
            element: <MappingsPage />,
          },
          {
            path: 'reports',
            element: <ReportsPage />,
          },
        ],
      },
    ],
  },
];
