import React, { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  type DriverMappingRecord,
  type FilterOperation,
  GetDriverMappingsV2Response,
  UpdateActionEnum,
} from '@amzn/allocations-service';
import { useTranslation } from 'react-i18next';
import QueryKey from 'src/api/QueryKey';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';
import {
  NextPageResponse,
  ServerSideTable,
} from 'src/common/components/server-side-table/ServerSideTable';
import { downloadFileWithPresignedUrl } from 'src/api/s3Api';
import { DriverMappingAction } from 'src/common/components/driver-mapping-components/utils/driverMapping';
import { sliceWorkflowColumnDefinitions } from 'src/common/components/driver-mapping-components/config/driver-mapping-table';
import { toDriverMappingRecordsByActionMap } from 'src/common/utils/driverMapping';

interface DriverMappingTableProps {
  filteringProperties: any;
  header: string;
  testId: string;
  sliceId: string | null;
  scenario: string | undefined;
}

const DriverMappingForSlice = ({
  filteringProperties,
  header,
  sliceId,
  scenario,
}: DriverMappingTableProps) => {
  const { data: driverMappingId } = useQuery({
    queryKey: [QueryKey.GetSliceInformation, sliceId],
    queryFn: () =>
      AllocationServiceApi.getSliceInformation({ sliceId: sliceId! }),
    select: (data) => data?.dmId,
    enabled: sliceId !== null,
  });
  const { t } = useTranslation();
  const colDefs = React.useMemo(() => sliceWorkflowColumnDefinitions(t), [t]);

  const actionToUpdateEnumMapping: Map<DriverMappingAction, UpdateActionEnum> =
    new Map([
      [DriverMappingAction.ADD, UpdateActionEnum.CREATE_OR_UPDATE],
      [DriverMappingAction.EDIT, UpdateActionEnum.CREATE_OR_UPDATE],
      [DriverMappingAction.DELETE, UpdateActionEnum.DELETE],
    ]);

  const downloadDriverMapping = useCallback(async (): Promise<void> => {
    const downloadableUrl =
      await AllocationServiceApi.getDownloadableDriverMappingPath({
        dmId: driverMappingId,
      });

    await downloadFileWithPresignedUrl(
      downloadableUrl.downloadUrl!,
      `${sliceId}-${driverMappingId}.json`,
    );
  }, [driverMappingId]);

  const getNextPageDriverMappingEntries = useCallback(
    async (
      pageSize: number,
      paginationToken?: string,
      filters?: Record<string, FilterOperation>,
    ): Promise<NextPageResponse> => {
      const response: GetDriverMappingsV2Response =
        await AllocationServiceApi.getDriverMappingsV2({
          dmId: driverMappingId,
          filters,
          startKey: paginationToken,
          limit: pageSize,
        });

      return {
        driverMappings: response.driverMappings!,
        paginationToken: response.paginationToken,
      };
    },
    [driverMappingId],
  );

  const updateDriverMappingEntries = useCallback(
    async (
      driverMappingEntries: Map<DriverMappingAction, DriverMappingRecord[]>,
    ) => {
      const driverMappingRecordsToUpdate =
        toDriverMappingRecordsByActionMap(driverMappingEntries);
      //TODO: Implement batched update calls
      const updateDriverMappingRecords = {
        records: Array.from(driverMappingRecordsToUpdate.entries()).flatMap(
          ([action, driverMappingRecords]) =>
            driverMappingRecords.map((record) => ({
              action: actionToUpdateEnumMapping.get(action)!,
              driverMapping: record,
            })),
        ),
        dmId: driverMappingId,
        isTerminalCall: true,
      };
      await AllocationServiceApi.updateDriverMappingEntries(
        updateDriverMappingRecords,
      );
    },
    [driverMappingId],
  );

  return (
    <>
      {driverMappingId && (
        <ServerSideTable
          key={driverMappingId}
          driverMappingId={driverMappingId}
          columnDefinitions={colDefs}
          header={header}
          filteringProperties={filteringProperties}
          testId="driver-mapping-table"
          handleDownloadCallback={downloadDriverMapping}
          fetchNextPage={getNextPageDriverMappingEntries}
          updateDriverMappingEntries={updateDriverMappingEntries}
          scenario={scenario}
        />
      )}
    </>
  );
};

export default DriverMappingForSlice;
