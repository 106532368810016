import Button from '@cloudscape-design/components/button';
import SpaceBetween from '@cloudscape-design/components/space-between';
import React, { lazy, startTransition, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Task, TaskType } from '@amzn/taskscheduling-service';
import { Action, Resource } from '@amzn/fae-auth-service';
import { useNavigate } from 'react-router-dom';
import { columnDefinitions } from './config/table-config';
import { filteringProperties } from './config/filter-property';
import { getContentDisplayOptions } from 'src/common/utils/table';
import { Notification } from 'src/common/constants/Notification';
import { useNotificationContext } from 'src/common/provider/NotificationProvider';
import { Table } from 'src/common/components/Table';
import { useMetrics } from 'src/common/provider/MetricsProvider';
import { Page } from 'src/common/types/Page';
import { PageAction } from 'src/common/types/PageAction';
import QueryKey from 'src/api/QueryKey';
import { TaskSchedulingServiceApi } from 'src/api/TaskSchedulingServiceApi';
import { getTasksWithExecutionState } from 'src/common/utils/task';
import { TaskWithStatus } from 'src/common/types/TaskWithStatus';
import { AuthServiceApi } from 'src/api/AuthServiceApi';

const DeleteModal = lazy(() => import('src/common/components/DeleteModal'));
const ExecuteAllocationModal = lazy(() => import('./ExecuteAllocationModal'));

const usePermissionQuery = (action: Action, item?: Task) =>
  useQuery({
    queryKey: [QueryKey.HasPermission, action, item],
    queryFn: () => {
      const input = item?.input?.slicedAllocationTaskInput;
      return AuthServiceApi.hasPermissions({
        action,
        resource: Resource.TASK,
        scenario: input?.scenario,
      });
    },
    enabled: Boolean(item),
  });

interface ManageSliceAllocationPageProps {
  disableDeleteButton?: boolean;
}

const ManageAllocationPage: React.FC<ManageSliceAllocationPageProps> = ({
  disableDeleteButton = true,
}) => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationContext();
  const metrics = useMetrics();
  const columnDef = useMemo(() => columnDefinitions(t), [t]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const sortingColumn = columnDef.find((def) => def.id === 'creationDate');

  const [selectedItems, setSelectedItems] = useState<Task[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [executeModal, setExecuteModal] = useState(false);

  const tasks = useQuery({
    queryKey: [
      QueryKey.ListTasksWithLatestExecutionState,
      TaskType.FAE_SLICED_ALLOCATIONS_TASK,
    ],
    queryFn: () =>
      TaskSchedulingServiceApi.listTaskWithLatestExecution({
        taskTypes: [TaskType.FAE_SLICED_ALLOCATIONS_TASK],
      }),
    select: (data) =>
      getTasksWithExecutionState(data.taskWithExecutionStateList ?? []),
  });

  const hasDeletePermission = usePermissionQuery(
    Action.DELETE,
    selectedItems[0],
  );

  const hasExecutePermission = usePermissionQuery(
    Action.EXECUTE,
    selectedItems[0],
  );

  const hasUpdatePermission = usePermissionQuery(
    Action.UPDATE,
    selectedItems[0],
  );

  const deleteTask = useMutation({
    mutationFn: TaskSchedulingServiceApi.deleteTask,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  const handleUpdate = () => {
    navigate(
      `/slice/group/${selectedItems[0].input?.slicedAllocationTaskInput?.scenario}`,
    );
  };

  const toggleDeleteModal = () => {
    startTransition(() => setDeleteModal(!deleteModal));
  };

  const toggleExecuteModal = () => {
    startTransition(() => setExecuteModal(!executeModal));
  };

  const navigateToAllocationDetails = () => {
    navigate(`/slice/allocation/details/${selectedItems[0].taskId}`);
  };

  const handleDelete = async (reason: string) => {
    const startTime = performance.now();
    try {
      await deleteTask.mutateAsync({
        taskId: selectedItems[0].taskId,
        deletionReason: reason,
      });
      addNotification({
        type: 'success',
        content: t(Notification.allocation.delete.success),
        dismissible: true,
      });
      metrics.publishCounter(
        `${Page.ManageSliceAllocation}.${PageAction.Delete}`,
        PageAction.Success,
        1,
      );
    } catch (error: any) {
      addNotification({
        type: 'error',
        header: t(Notification.allocation.delete.error),
        content: error?.message,
        dismissible: true,
      });
      metrics.publishCounter(
        `${Page.ManageSliceAllocation}.${PageAction.Delete}`,
        PageAction.Failure,
        1,
      );
    }
    const endTime = performance.now();
    metrics.publishTime(
      `${Page.ManageSliceAllocation}.${PageAction.Delete}`,
      endTime - startTime,
    );
    metrics.publishCounter(Page.ManageSliceAllocation, PageAction.Delete, 1);
    toggleDeleteModal();
  };

  return (
    <div data-testid="manage-slice-allocation-page">
      {deleteModal && (
        <DeleteModal
          header={t('delete_allocation')}
          name={selectedItems[0].name}
          visible={deleteModal}
          onClose={toggleDeleteModal}
          onDelete={handleDelete}
        />
      )}
      {executeModal && (
        <ExecuteAllocationModal
          visible={executeModal}
          onClose={toggleExecuteModal}
          allocation={selectedItems[0]}
        />
      )}
      <Table<TaskWithStatus>
        variant="full-page"
        loading={tasks.isFetching}
        trackBy="taskId"
        testid="manage-slice-allocation-table"
        data={tasks.data ?? []}
        header={t('manage_allocation')}
        columnDefinitions={columnDef}
        contentDisplayOptions={getContentDisplayOptions(columnDef)}
        selectedItems={selectedItems}
        onSelectionChange={setSelectedItems}
        pagination={true}
        preferences={true}
        empty={{
          title: t('empty_allocation'),
          description: t('empty_allocation_description'),
        }}
        selectionType="single"
        filteringProperties={filteringProperties(t)}
        sortingState={
          sortingColumn && {
            sortingColumn,
            isDescending: true,
          }
        }
        buttons={
          <SpaceBetween direction="horizontal" size="s">
            <Button
              disabled={
                !selectedItems.length || !hasUpdatePermission.data?.isAuthorized
              }
              loading={hasUpdatePermission.isFetching}
              data-testid="update-slice-allocation"
              onClick={handleUpdate}
            >
              {t('update')}
            </Button>
            <Button
              disabled={
                // Disabling delete for now since there's no creation workflow from UI
                disableDeleteButton ||
                !selectedItems.length ||
                !hasDeletePermission.data?.isAuthorized
              }
              loading={hasDeletePermission.isFetching}
              data-testid="delete-slice-allocation"
              onClick={toggleDeleteModal}
            >
              {t('delete')}
            </Button>
            <Button
              disabled={!selectedItems.length}
              data-testid="slice-allocation-history"
              onClick={navigateToAllocationDetails}
            >
              {t('execution_details')}
            </Button>
            <Button
              disabled={
                !selectedItems.length ||
                !hasExecutePermission.data?.isAuthorized
              }
              loading={hasExecutePermission.isFetching}
              data-testid="execute-slice-allocation"
              onClick={toggleExecuteModal}
              variant="primary"
            >
              {t('execute')}
            </Button>
          </SpaceBetween>
        }
      />
    </div>
  );
};

export default ManageAllocationPage;
